import React, { CSSProperties, FC, ReactNode, useMemo } from 'react'
import { CSSTransition } from 'react-transition-group'
import { WebAppIcon } from '../assets/icons/icons'
import { useScreenSize } from '../hooks'
import { CSS_VARS } from '../resources/cssVariableConfig'
import { CustomIcon } from './CustomIcon/CustomIcon'
import styles from './WrapperWithTitleAndAnimation.module.less'

/**
 * @param props The props object
 * @param props.title The title of the component
 * @param props.icon The icon used by the component
 * @param props.isAnimated Whether the component is animated
 * @param props.animationStartsWhen The boolean that decides when to start the animation
 * @param props.style The optional CSS styling object
 * @param props.children The children object
 * @param props.hasTopMargin The boolean that decides if a top margin should be added
 * @param props.tagline The tagline of the component
 * @param props.elementReference The reference used for getting the size of the element
 * @returns The WrapperWithTitleAndAnimation component
 */
export const WrapperWithTitleAndAnimation: FC<{
  title?: string
  icon?: WebAppIcon
  isAnimated?: boolean
  animationStartsWhen?: boolean
  style?: CSSProperties
  children?: ReactNode
  hasTopMargin?: boolean
  tagline?: string
  elementReference?: (node: HTMLDivElement | null) => void
}> = ({
  title,
  icon,
  isAnimated,
  animationStartsWhen,
  style,
  children,
  tagline,
  elementReference,
  hasTopMargin = true,
}) => {
  const { isMobileView } = useScreenSize()

  const innerContent = useMemo(
    () => (
      <div className="innerContent" style={{ marginTop: hasTopMargin ? 70 : 'initial', ...style }}>
        {icon && (
          <div className={styles.iconContainer}>
            <CustomIcon icon={icon} />
          </div>
        )}

        {title && (
          <hgroup>
            {tagline && (
              <h1
                style={{
                  color: CSS_VARS.questionFontColor,
                  fontSize: isMobileView ? `calc(${CSS_VARS.fontSize} - 3px)` : CSS_VARS.fontSize,
                  fontWeight: CSS_VARS.fontWeight,
                  textAlign: 'center',
                  marginBottom: 25,
                }}
              >
                {tagline}
              </h1>
            )}
            <h1
              style={{
                color: CSS_VARS.questionFontColor,
                fontSize: isMobileView ? `calc(${CSS_VARS.questionFontSize} - 3px)` : CSS_VARS.questionFontSize,
                fontWeight: CSS_VARS.fontWeight,
                lineHeight: 1.3,
                textAlign: 'center',
              }}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
              className={styles.question}
            />
          </hgroup>
        )}

        {children}
      </div>
    ),
    [children, hasTopMargin, icon, isMobileView, style, tagline, title],
  )

  return isAnimated ? (
    <CSSTransition in={animationStartsWhen} timeout={500} classNames="innerWindowAnimation" appear unmountOnExit>
      <div ref={elementReference}>{innerContent}</div>
    </CSSTransition>
  ) : (
    <div ref={elementReference}>{innerContent}</div>
  )
}
