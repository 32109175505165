import { useEffect, useMemo, useState } from 'react'
interface AuthData {
  token: string | null
  origin?: string | null
}

let initialized = false

/**
 * The hook is responsible for loading the token from fragment and storing in local storage
 *
 * @returns The token
 */
export const useAuthToken = () => {
  useEffect(() => {
    if (!initialized) {
      initialized = true
    }
  }, [])

  const [result, setResult] = useState<AuthData>({
    token: null,
    origin,
  })

  const sessionData = useMemo(
    () => (data: AuthData) => {
      Object.keys(data).forEach((key) => {
        const value = data[key as keyof AuthData]
        if (data.token && typeof value !== 'undefined' && value !== null) {
          window.appStorage.setItem(key, value.toString())
        }
      })
    },
    [],
  )
  useEffect(() => {
    sessionData(result)
  }, [result, sessionData])

  useEffect(() => {
    const tokenFromFragment = window.location.hash?.slice(1)

    const urlParams = new URLSearchParams(window.location.search)

    const getToken = async () => {
      if (!urlParams.get('clientId') || !urlParams.get('authCode')) {
        throw new Error('No clientId or authCode provided')
      }

      const response = await fetch(`${window.xundEnvironment.PP_ID_API_URL}/token`, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientId: urlParams.get('clientId'),
          code: urlParams.get('authCode'),
          grant_type: 'authorization_code',
        }),
      })

      const responseJson = await response.json()

      if (responseJson) {
        setResult({ token: responseJson.access_token, origin: responseJson.origin })
      }
    }

    const shouldUseCachedAuth = !!window.appStorage?.getItem('token')

    if (shouldUseCachedAuth) {
      setResult({
        token: window.appStorage?.getItem('token'),
        origin: window.appStorage?.getItem('origin'),
      })
    } else if (tokenFromFragment) {
      window.location.replace('#')
      if (typeof window.history.replaceState == 'function') {
        history.replaceState({}, '', window.location.href.slice(0, -1))
      }

      setResult({ token: tokenFromFragment })
    } else {
      getToken()
    }
  }, [])

  return result
}
