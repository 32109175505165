import styled from '@emotion/styled'
import { Col as ColOriginal, ColProps as ColPropsOriginal, Pagination, Typography } from 'antd'
import { CSS_VARS, JS_STYLING } from '../../../resources/cssVariableConfig'
import React from 'react'

interface ColProps extends ColPropsOriginal {
  needsSmallerFont: boolean
}

const Col = (props: ColProps) => {
  const { needsSmallerFont, ...otherProps } = props
  return <ColOriginal {...otherProps} />
}

const { Title } = Typography

export const LoadingPlaceholder = styled.div`
  height: 870px;
  display: flex;
`

export const ResultsContainer = styled.div`
  margin-top: 50px;

  :last-child {
    margin-top: 37px;
    margin-bottom: 137px;
  }
`

export const ListItemContainer = styled.div<{ needsLessPadding?: boolean }>(
  `
  div {
    padding-right: 100px;
    padding-bottom: 13px;
    margin-bottom: 13px;
  }

  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    div {
      padding-right: 0px;
    }
  }
  `,
  (props) =>
    props.needsLessPadding &&
    `
    div {
      padding-right: 10px;
    }
    `,
)

export const StyledCol = styled(Col)<{ needsSmallerFont?: boolean }>(
  `
  border-bottom: 0.5px solid #f0f0f0;
  padding-bottom: 13px;
  margin-bottom: 13px;

  display: flex;
  align-items: center;

  button {
    width: 100%;
    border-radius: 13px !important;
    box-shadow: none;

    span {
      font-family: ${CSS_VARS.fontFamily};
    }
  }
  `,
  (props) =>
    props.needsSmallerFont &&
    `
    button {
      font-size: 12px;
    }
    `,
)

export const ResultType = styled(Title)`
  color: ${CSS_VARS.lightTextColor} !important;
  margin-bottom: 13px !important;

  font-family: ${CSS_VARS.fontFamily};
  font-size: ${CSS_VARS.fontSize} !important;
  font-style: ${CSS_VARS.fontStyle};
  font-weight: ${CSS_VARS.fontWeight} !important;
`

export const StyledPagination = styled(Pagination)`
  li a,
  li.ant-pagination-prev svg,
  li.ant-pagination-next svg,
  li.ant-pagination-jump-prev span,
  li.ant-pagination-jump-next span,
  li.ant-pagination-jump-prev svg,
  li.ant-pagination-jump-next svg {
    color: ${CSS_VARS.DARK_GREY_COLOR} !important;
  }

  li.ant-pagination-disabled svg {
    color: #cccccc !important;
  }

  li.ant-pagination-item,
  li.ant-pagination-item:hover,
  li.ant-pagination-prev:active button,
  li.ant-pagination-next:active button,
  li.ant-pagination-prev:hover button,
  li.ant-pagination-next:hover button {
    border-radius: 16px;
  }

  li.ant-pagination-item-active,
  li.ant-pagination-item-active:hover {
    background-color: ${CSS_VARS.primaryColor};
    border-radius: 16px;
    border-color: ${CSS_VARS.primaryColor};

    a {
      color: white !important;
    }
  }
`
