import React, { FC, ReactNode } from 'react'

export const Highlight: FC<{
  children: ReactNode
  term: string | undefined
}> = ({ children, term }) => {
  const text = children
  if (!text) return <></>
  if (!term || !term.trim()) {
    return <span>{text}</span>
  }
  const regex = new RegExp(`(${term})`, 'gi')
  const parts = text.toString().split(regex)
  return (
    <span>
      {parts
        .filter((part: string) => part)
        .map((part: string, i: number) =>
          regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>,
        )}
    </span>
  )
}
