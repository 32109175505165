import { Button } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import React, { CSSProperties, FC, useMemo } from 'react'
import { useWindowSize } from 'usehooks-ts'
import { mobileMaxWidth } from '../../../hooks'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator'

type ButtonType = 'outlined' | 'filled' | 'confirm' | 'skip'

//TODO refactor component using styled
export const StyledButton: FC<{
  id?: string
  className?: string
  testId?: string
  title: string
  type: ButtonType
  size?: SizeType
  style?: CSSProperties
  classNames?: string[]
  disabled?: boolean
  onClick?: () => void
  isLoading?: boolean
}> = ({ testId, title, type, size, style, classNames, disabled, onClick, isLoading }) => {
  const { width: windowWidth } = useWindowSize()

  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])

  const buttonStyles = useMemo(() => {
    switch (type) {
      case 'confirm': {
        return {
          color: CSS_VARS.confirmButtonFontColor,
          backgroundColor: CSS_VARS.successColor,
          borderColor: CSS_VARS.successColor,
          fontWeight: CSS_VARS.buttonWeight,
          fontSize: CSS_VARS.fontSize,
        }
      }
      case 'outlined': {
        return {
          color: CSS_VARS.outlinedButtonFontColor,
          backgroundColor: CSS_VARS.outlinedButtonBackgroundColor,
          borderColor: CSS_VARS.outlinedButtonBorderColor,
          fontWeight: CSS_VARS.buttonWeight,
          fontSize: CSS_VARS.fontSize,
        }
      }
      case 'skip': {
        return {
          color: CSS_VARS.skipButtonFontColor,
          backgroundColor: CSS_VARS.skipButtonBackgroundColor,
          borderColor: CSS_VARS.skipButtonBorderColor,
          fontWeight: CSS_VARS.buttonWeight,
          fontSize: CSS_VARS.fontSize,
        }
      }
      case 'filled': {
        return {
          color: CSS_VARS.filledButtonFontColor,
          backgroundColor: CSS_VARS.primaryColor,
          borderColor: CSS_VARS.primaryColor,
          fontWeight: CSS_VARS.buttonWeight,
          fontSize: CSS_VARS.fontSize,
        }
      }
      default: {
        return {
          color: CSS_VARS.skipButtonFontColor,
          backgroundColor: CSS_VARS.skipButtonBackgroundColor,
          borderColor: CSS_VARS.skipButtonBorderColor,
          fontWeight: CSS_VARS.buttonWeight,
          fontSize: CSS_VARS.fontSize,
        }
      }
    }
  }, [type])

  const buttonSize = useMemo(() => {
    if (type === 'outlined') {
      return CSS_VARS.buttonSize
    }
    if (type === 'filled') {
      return CSS_VARS.buttonSize
    }
    if (type === 'confirm') {
      return CSS_VARS.buttonSize
    }
    if (type === 'skip') {
      return CSS_VARS.buttonSize
    }
    return 'middle'
  }, [type])

  const buttonType = useMemo(() => {
    if (type === 'outlined' || type === 'skip') {
      return 'default'
    }
    if (type === 'filled' || type === 'confirm' || type === 'delete') {
      return 'primary'
    }
    return 'default'
  }, [type])

  return (
    <Button
      data-testid={testId}
      type={buttonType}
      size={size || (buttonSize as SizeType)}
      style={{
        ...buttonStyles,
        ...style,
        borderWidth: 0.5,
        borderRadius: 17,
        ...(isMobile ? { height: 'auto', wordBreak: 'break-word', whiteSpace: 'normal' } : {}),
        ...(disabled && !isLoading ? { opacity: 0.4 } : {}),
      }}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={classNames ? classNames.join(' ') : undefined}
    >
      {!isLoading && title}
      {isLoading && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LoadingIndicator color={type === 'filled' ? 'secondary' : 'primary'} />
        </div>
      )}
    </Button>
  )
}
