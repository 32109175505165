import React, { Dispatch, FC, SetStateAction } from 'react'
import { UnifiedMedicalItem } from 'medical-engine-api'
import { useI18n } from '../../../hooks'
import { ContentLibraryListItem } from '../ContentLibraryListItem/ContentLibraryListItem'
import { LoadingIndicator } from '../../../common/Indicators/LoadingIndicator'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './ContentLibrarySelector.module.less'
import { LoadingType } from '../ContentLibrary'

/**
 * @param props The props object
 * @param props.frequentOptions The frequent options
 * @param props.infrequentOptions The infrequent options
 * @param props.isLoading The loading state
 * @param props.searchText The search text
 * @param props.onSelect The method called when an item is selected
 * @param props.onScrollEnd The method called at the end of the scroll
 * @param props.elementReference The reference object
 * @returns The ContentLibrarySelector component
 */

export const ContentLibrarySelector: FC<{
  frequentOptions: UnifiedMedicalItem[]
  infrequentOptions: UnifiedMedicalItem[]
  isLoading: LoadingType
  searchText?: string
  onSelect: (val: string) => void
  onScrollEnd: Dispatch<SetStateAction<boolean>>
  elementReference?: (node: HTMLDivElement | null) => void
}> = ({ frequentOptions, infrequentOptions, isLoading, searchText, onSelect, elementReference, onScrollEnd }) => {
  const { i18n } = useI18n()

  const searchFilter = (item: UnifiedMedicalItem) => {
    const mergedFields = `${item.name} ${item.description}`.toLowerCase()
    return mergedFields.includes((searchText ?? '').toLowerCase())
  }

  return (
    <>
      {isLoading.frequent && <LoadingIndicator delay={200} fullscreen={false} />}
      <div
        className={styles.contentLibrarySelectorContainer}
        onScroll={(event) => {
          const target = event.target as HTMLElement
          onScrollEnd(target.offsetHeight + target.scrollTop >= target.scrollHeight)
        }}
      >
        <>
          <h2 style={{ fontFamily: CSS_VARS.fontFamily, fontSize: CSS_VARS.fontSize }}>
            {i18n('xund.contentLibrary.frequentlySearched')}
          </h2>

          {frequentOptions.filter(searchFilter).map((item, index) => (
            <ContentLibraryListItem
              elementReference={index === 0 ? elementReference : undefined}
              key={item.id}
              item={item}
              onSelect={onSelect}
              searchText={searchText}
              lastItem={index === frequentOptions.length - 1}
            />
          ))}

          <h2 style={{ fontFamily: CSS_VARS.fontFamily, fontSize: CSS_VARS.fontSize, marginTop: 40 }}>
            {i18n('xund.contentLibrary.illnessesAndSymptomsAZ')}
          </h2>

          {isLoading.infrequent && !isLoading.frequent && <LoadingIndicator delay={200} inline />}

          {infrequentOptions.filter(searchFilter).map((item, index) => (
            <ContentLibraryListItem
              key={item.id}
              item={item}
              onSelect={onSelect}
              searchText={searchText}
              lastItem={index === infrequentOptions.length - 1}
            />
          ))}
        </>
      </div>
    </>
  )
}
