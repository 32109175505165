import React, { FC, ReactNode, useCallback, useState } from 'react'
import { ConfigProvider, Select } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { CSS_VARS } from '../../resources/cssVariableConfig'

type Option = { value: string; label: string; excluding?: boolean | null }

interface Props {
  options: Option[] | Array<Record<string, any>>
  onSelect?: (selected: string | string[]) => void
  onGroupedSelect?: (value: string[], option: Record<string, any>) => void
  placeholder?: string
  prefixIcon?: ReactNode
  isMulti?: boolean
  hasSearch?: boolean
  notFoundContent?: ReactNode
  size?: 'small' | 'middle' | 'large'
  customValue?: string[] | null
  defaultValue?: string[]
  isOptionGroupingEnabled?: boolean
}

export const SelectInput: FC<Props> = (props) => {
  const {
    options,
    onSelect,
    onGroupedSelect,
    placeholder,
    isMulti,
    hasSearch,
    notFoundContent,
    size,
    customValue,
    defaultValue,
    isOptionGroupingEnabled = false,
  } = props
  const [selectedAnswer, setSelectedAnswer] = useState<string[] | null>()

  const [isOpen, setOpen] = useState<boolean | undefined>(undefined)

  const groupOptions = useCallback((optionsToGroup: Option[] | Array<Record<string, any>>) => {
    const groups: Record<string, Record<string, any>> = {}
    const excludingOptions = optionsToGroup
      .filter((option) => option.excluding)
      .sort((a, b) => a.label.localeCompare(b.label))

    optionsToGroup
      .filter((optionToGroup) => !optionToGroup.excluding)
      .sort((a, b) => a.label.localeCompare(b.label))
      .forEach((optionToGroup) => {
        if (!groups[optionToGroup.label[0]]) {
          groups[optionToGroup.label[0]] = {
            label: optionToGroup.label[0],
            options: [{ value: optionToGroup.value, label: optionToGroup.label }],
          }
        } else {
          groups[optionToGroup.label[0]].options.push({ value: optionToGroup.value, label: optionToGroup.label })
        }
      })
    return [...Object.values(groups), ...excludingOptions]
  }, [])

  return (
    <ConfigProvider renderEmpty={() => notFoundContent}>
      <Select
        showSearch={!!hasSearch}
        onChange={(value: string[], option) => {
          let updatedValue = value
          const flattenedOptions = options.flatMap((innerOption: any) =>
            innerOption.options ? innerOption.options : innerOption,
          )

          if (Array.isArray(option) && option.length > 0) {
            const lastOption = option[option.length - 1]
            if (lastOption.excluding) {
              updatedValue = [lastOption.value]
              setOpen(false)
            }
            if (!lastOption.excluding) {
              updatedValue = flattenedOptions
                .filter((currentOption) => !currentOption.excluding && value.includes(currentOption.value))
                .map((currentOption) => currentOption.value)
            }
          }
          setSelectedAnswer(updatedValue)
          if (onSelect) {
            onSelect(updatedValue)
          }
          if (onGroupedSelect) {
            onGroupedSelect(updatedValue, option)
          }
        }}
        optionFilterProp="label"
        value={customValue ?? defaultValue ?? selectedAnswer}
        placeholder={placeholder}
        style={{ width: '100%', fontFamily: CSS_VARS.fontFamily }}
        size={size ?? 'large'}
        options={isOptionGroupingEnabled ? groupOptions(options) : options}
        mode={isMulti ? 'multiple' : undefined}
        showArrow={isMulti}
        suffixIcon={<DownOutlined style={{ color: 'black' }} onClick={() => setOpen((prevState) => !prevState)} />}
        open={isOpen}
        onDropdownVisibleChange={() => setOpen(undefined)}
      />
    </ConfigProvider>
  )
}
