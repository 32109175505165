import React, { FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useDebouncedState } from 'xund-react-utils'
import { UnifiedMedicalItem, UnifiedMedicalItemResponse } from 'medical-engine-api'
import { Col, Row } from 'antd'
import { useElementSize } from 'usehooks-ts'
import { useApiGatewayContext } from '../../context'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { SearchInput } from '../General/SearchInput/SearchInput'
import { DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS } from '../../constants'
import { OpaqueListOverlay } from '../OpaqueListOverlay/OpaqueListOverlay'
import { ContentLibrarySelector } from './ContentLibrarySelector/ContentLibrarySelector'
import { ContentLibraryListItemDetails } from './ContentLibraryListItemDetails/ContentLibraryListItemDetails'
import styles from './ContentLibrary.module.less'

export interface LoadingType {
  frequent?: boolean
  infrequent?: boolean
}

/**
 * @param props The props object
 * @param props.contentLibraryItemId The id of the current item
 * @param props.onItemSelected The method handling selection
 * @returns The ContentLibrary component
 */
export const ContentLibrary: FC<{
  contentLibraryItemId: string
  onItemSelected: (id: string) => void
}> = ({ contentLibraryItemId, onItemSelected }) => {
  const [frequentContentLibraryData, setFrequentContentLibraryData] = useState<UnifiedMedicalItem[]>([])
  const [infrequentContentLibraryData, setInfrequentContentLibraryData] = useState<UnifiedMedicalItem[]>([])
  const { setValue: setSearchText, debouncedValue: searchText } = useDebouncedState(
    '',
    DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS,
  )
  const [isLoading, setIsLoading] = useState<LoadingType>({})
  const [error, setError] = useState<Error>()
  const [atScrollEnd, setAtScrollEnd] = useState(false)

  const { apiGateway } = useApiGatewayContext()
  const [ref, { height }] = useElementSize()

  /**
   * Fetches Content Library data
   */
  const getContentLibraryData = useCallback(
    async (
      setData: (value: SetStateAction<UnifiedMedicalItem[]>) => void,
      isFrequent: boolean | 'all' = 'all',
      searchTerm?: string,
    ) => {
      const loadingKey = isFrequent ? 'frequent' : 'infrequent'
      try {
        const currentLoading = isLoading
        currentLoading[loadingKey] = true
        setIsLoading(currentLoading)

        const { data } = await apiGateway.get<UnifiedMedicalItemResponse>(`v1/contentLibrary`, {
          params: { searchTerm, isFrequent: isFrequent === 'all' ? undefined : isFrequent },
        })

        setData(data.items)
      } catch (err) {
        setError(err as Error)
      } finally {
        const currentLoading = isLoading
        delete currentLoading[loadingKey]
        setIsLoading(currentLoading)
      }
    },
    [apiGateway, isLoading],
  )

  /**
   * Decides which data to fetch based on search term
   */
  const fetchData = useCallback(
    (searchTerm: string) => {
      if (searchTerm) {
        return getContentLibraryData(setInfrequentContentLibraryData, 'all', searchTerm)
      }
      return Promise.all([
        getContentLibraryData(setFrequentContentLibraryData, true, searchTerm),
        getContentLibraryData(setInfrequentContentLibraryData, false, searchTerm),
      ])
    },
    [getContentLibraryData],
  )

  // disable search on every keystore for now, since the initially loaded data just can be filtered:
  // useEffect(() => {
  //   fetchData(searchText)
  // }, [fetchData, getContentLibraryData, searchText])

  useEffect(() => {
    if (
      contentLibraryItemId === '' &&
      frequentContentLibraryData.length === 0 &&
      infrequentContentLibraryData.length === 0
    ) {
      setSearchText('')
      fetchData(searchText)
    }
  }, [
    contentLibraryItemId,
    fetchData,
    frequentContentLibraryData.length,
    infrequentContentLibraryData.length,
    searchText,
    setSearchText,
  ])

  if (error) {
    throw error
  }

  return (
    <WrapperWithTitleAndAnimation style={{ justifyContent: 'unset' }} hasTopMargin={false}>
      {!contentLibraryItemId ? (
        <div className={styles.container}>
          <Row>
            <Col span={24}>
              <SearchInput setSearchText={setSearchText} style={{ marginBottom: 0 }} />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '100%' }}>
              <ContentLibrarySelector
                frequentOptions={frequentContentLibraryData}
                infrequentOptions={infrequentContentLibraryData}
                searchText={searchText}
                isLoading={isLoading}
                onSelect={onItemSelected}
                elementReference={ref}
                onScrollEnd={setAtScrollEnd}
              />
            </Col>
          </Row>
          <OpaqueListOverlay isHidden={atScrollEnd} height={height} />
        </div>
      ) : (
        <ContentLibraryListItemDetails id={contentLibraryItemId} />
      )}
    </WrapperWithTitleAndAnimation>
  )
}
