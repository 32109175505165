import React, { CSSProperties, FC } from 'react'
import { Input } from 'antd'
import { useI18n } from '../../../hooks'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './SearchInput.module.less'

/**
 * @param props The props object
 * @param props.style The stylings to use
 * @param props.setSearchText The setter for the search text
 * @returns The SearchInput component
 */
export const SearchInput: FC<{ style?: CSSProperties; setSearchText: (val: string) => void }> = ({
  style,
  setSearchText,
}) => {
  const { i18n } = useI18n()

  return (
    <Input
      className={styles.input}
      placeholder={i18n('xund.general.search')}
      style={{
        width: '100%',
        borderRadius: 5,
        fontFamily: CSS_VARS.fontFamily,
        borderWidth: 0.5,
        fontSize: 14,
        ...style,
      }}
      onChange={(e) => setSearchText(e.target.value)}
      suffix={<CustomIcon icon="zoom" color={CSS_VARS.primaryColor} />}
      size="large"
    />
  )
}
