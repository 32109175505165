import styled from '@emotion/styled'
import { CSS_VARS, JS_STYLING } from '../../../resources/cssVariableConfig'

export const ListItem = styled.div<{ isLast: boolean }>(
  `
  border-bottom: 0.5px solid #f0f0f0;
  padding-bottom: 7px;
  margin-bottom: 7px;

  h3,
  p {
    line-height: 18px;

    font-family: ${CSS_VARS.fontFamily} !important;
    font-size: ${CSS_VARS.fontSize} !important;
  }

  p {
    color: ${CSS_VARS.lightTextColor};
    margin: 0;

    font-style: ${CSS_VARS.fontStyle};
    font-weight: ${CSS_VARS.fontWeight};
  }

  mark{
    padding: 0 0.1em;
    margin: 0 -0.1em;
    background-color: ${CSS_VARS.reportProgressBackgroundColor};
  }

  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    border: none;
  }
`,
  (props) =>
    props.isLast &&
    `
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
`,
)

export const Link = styled.span`
  color: ${CSS_VARS.primaryColor};
  cursor: pointer;
`
