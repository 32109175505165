import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { Badge } from '../../xund-ui-legacy-components'
import { useI18n } from '../../../hooks'
import { ContentLibraryIllnessDetails, SpecializationType } from '../../../models'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { LoadingIndicator } from '../../../common/Indicators/LoadingIndicator'
import { QuickSolutions } from '../../QuickSolutions/QuickSolutions'
import { useApiGatewayContext } from '../../../context'
import { EmergencyWarningWithCallNumber } from '../../QuickSolutions/EmergencyWarningWithCallNumber/EmergencyWarningWithCallNumber'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './ContentLibraryListItemDetails.module.less'

/**
 * @param props The props object
 * @param props.id The id of the current list item
 * @returns The ContentLibraryListItemDetails component
 */
export const ContentLibraryListItemDetails: FC<{ id: string }> = ({ id }) => {
  const [illnessData, setIllnessData] = useState<ContentLibraryIllnessDetails | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error>()

  const { apiGateway } = useApiGatewayContext()
  const { i18n } = useI18n()

  /**
   * Fetches Content Library Illness Details
   */
  const getContentLibraryIllnessDetails = useCallback(
    async (illnessId: string) => {
      try {
        setIsLoading(true)

        const { data } = await apiGateway.get<ContentLibraryIllnessDetails>(`v1/contentLibrary/illnesses/${illnessId}`)

        setIllnessData(data)
      } catch (err) {
        setError(err as Error)
      } finally {
        setIsLoading(false)
      }
    },
    [apiGateway],
  )

  const isEmergency = useMemo(
    () => illnessData?.specializations?.some((item) => item.type === SpecializationType.Hospital),
    [illnessData?.specializations],
  )

  useEffect(() => {
    getContentLibraryIllnessDetails(id)
  }, [getContentLibraryIllnessDetails, id])

  if (isLoading) {
    return <LoadingIndicator delay={200} />
  }

  if (error) {
    throw error
  }

  return (
    illnessData && (
      <div className={styles.contentLibraryDetailsContainer}>
        <div className={styles.name}>
          <div className={styles.header}>
            <h2 style={{ color: CSS_VARS.questionFontColor, fontSize: CSS_VARS.modalTitleFontSize }}>
              {illnessData.name}
            </h2>
            <p style={{ color: illnessData.recommendation.textColor, fontSize: CSS_VARS.fontSize }}>
              {illnessData?.recommendation?.text}
            </p>
          </div>

          <article>{illnessData.description}</article>
        </div>

        {illnessData.detailedOverview && (
          <>
            <hr />
            <div className={styles.overview}>
              <h3 style={{ color: CSS_VARS.questionFontColor, fontSize: CSS_VARS.fontSize }}>
                {i18n('xund.contentLibrary.details.overview')}
              </h3>

              <article style={{ color: CSS_VARS.textColor }}>
                <span dangerouslySetInnerHTML={{ __html: illnessData.detailedOverview }} />
              </article>
            </div>
          </>
        )}

        <div className={styles.commonSymptoms}>
          <h3 style={{ color: CSS_VARS.questionFontColor, fontSize: CSS_VARS.fontSize }}>
            {i18n('xund.contentLibrary.details.commonSymptoms')}
          </h3>

          <div className={styles.container}>
            {illnessData.symptoms.map((symptom) => (
              <Badge
                key={symptom.id}
                className={styles.symptom}
                style={{ color: CSS_VARS.questionFontColor, fontSize: 14 }}
              >
                {symptom.name}
              </Badge>
            ))}
          </div>
        </div>

        {illnessData.detailedDiagnosis && (
          <>
            <hr />
            <div className={styles.diagnosis}>
              <h3 style={{ color: CSS_VARS.questionFontColor, fontSize: CSS_VARS.fontSize }}>
                {i18n('xund.contentLibrary.details.diagnosis')}
              </h3>

              <article style={{ color: CSS_VARS.textColor }}>
                <span dangerouslySetInnerHTML={{ __html: illnessData.detailedDiagnosis }} />
              </article>
            </div>
          </>
        )}

        <hr />
        <div className={styles.getWell}>
          <h3 style={{ color: CSS_VARS.questionFontColor, fontSize: CSS_VARS.fontSize }}>
            {i18n('xund.contentLibrary.details.quickSolutions')}
          </h3>

          <div className={styles.quickSolutionsContainer}>
            <QuickSolutions
              quickSolutions={illnessData.quickSolutions}
              specializations={illnessData.specializations}
              localSpecializations={illnessData.localSpecializations}
            />

            {isEmergency && <EmergencyWarningWithCallNumber />}
          </div>
        </div>

        {illnessData.detailedOutlook && (
          <>
            <hr />
            <div className={styles.outlook}>
              <h3 style={{ color: CSS_VARS.questionFontColor, fontSize: CSS_VARS.modalTitleFontSize }}>
                {i18n('xund.contentLibrary.details.outlook')}
              </h3>

              <article style={{ color: CSS_VARS.textColor }}>
                <span dangerouslySetInnerHTML={{ __html: illnessData.detailedOutlook }} />
              </article>
            </div>
          </>
        )}

        <div className={styles.thieme}>
          <span>{i18n('xund.contentLibrary.details.informationBy')}</span>
          <CustomIcon icon="thieme" />
        </div>
      </div>
    )
  )
}
